import React from 'react';
import { BrowserRouter as Router,  Route, Switch, Link, NavLink, Redirect } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import OrderPage from "./pages/OrderPage";
import UserPage from "./pages/UserPage";
import NoMatchPage from "./pages/NoMatchPage";
import './App.css';
import AppLayout from "./layouts/AppLayout";
import BlankLayout from "./layouts/BlankLayout";
import DefaultPage from "./pages/DefaultPage";

function App() {
  return (
    <Router>
        <Switch>
          <Route exact path="/" render={ () => (
              <AppLayout component={OrderPage} />
          )} />
          <Route path="/login" render={ () => (
              <BlankLayout component={LoginPage} />
          )} />
          <Route path="/logout" component={LogoutPage} />

          <Route path="/order" render={ () => (
              <AppLayout component={OrderPage} />
          )} />

          <Route path="/user" render={ () => (
              <AppLayout component={UserPage} />
          )} />

          <Route path="/default" render={ () => (
              <AppLayout component={DefaultPage} />
          )} />

          <Route render={ () => (
              <AppLayout component={NoMatchPage} />
          )} />
        </Switch>
    </Router>
  );
}

export default App;
