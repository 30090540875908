import React from 'react'
import { Route, Redirect } from "react-router-dom";


export default {
    isAuthenticated() {
        if (!localStorage.getItem('token')) {
            return false;
        }
        return true;
    },
}