import React from 'react'

export default {
    clear() {
        localStorage.clear();
    },

    getItem(key) {
        return localStorage.getItem(key);
    }
}