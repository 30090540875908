import React, {useState} from 'react'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert'
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LoginService from "../services/LoginService";
import UserService from "../services/UserService";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="http://media-projekt.pl/">
                DriverMobileApp by media-projekt
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default props => {
    const classes = useStyles();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');

    function updateUserData() {
        return UserService.getMe().then(response => {
            console.log(response.data)
            localStorage.setItem('userData', response.data);
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        LoginService.Login(username, password).then(response => {
            console.log(response.data)
            localStorage.setItem('token', response.data)
            UserService.getMe().then(response => {
                localStorage.setItem('userData', JSON.stringify(response.data));
                return window.location.href = '/order';
            });
        }).catch(error => {
            if (error.response.status === 422) {
                setLoginError('Nieprawidłowe dane logowania lub taki użytkownik nie istnieje.');
            } else {
                setLoginError('Wystąpił błąd logowania. Proszę skontaktować się z administartorem systemu.');
            }
        });
    }

    function loginErrorDisplay() {
        if (loginError) {
            return (<Alert severity="error">{loginError}</Alert>);
        } else {
            return ('');
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    DriverMobileApp
                </Typography>
                <div>{ loginErrorDisplay() }</div>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Użytkownik"
                        name="username"
                        autoComplete="current-username"
                        autoFocus
                        onChange={e => setUsername(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Hasło"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Zaloguj
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}