import axios from 'axios'


export default
{

    request(url, method, data, headers) {
        const apiUrl = `${process.env.REACT_APP_API_URL}`
        let request = {};

        if (method === 'GET') {
            request = {
                method: method,
                url: `${apiUrl}${url}`,
                params: data,
                headers: this.getRequestHeaders(headers)
            }
        } else {
            request = {
                method: method,
                url: `${apiUrl}${url}`,
                data: this.preparePostData(data),
                headers: this.getRequestHeaders(headers)
            }
        }

        return axios(request);
    },

    getRequestHeaders(headers) {
        let requestHeaders = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }

        return requestHeaders;
    },

    preparePostData(data) {
        return JSON.stringify(data);
    },

    getToken() {

    }



}