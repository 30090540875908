import  ApiService from './ApiService'
import StorageHelper from "../helpers/StorageHelper";

export default {

    getMe() {
        return ApiService.request('/me', 'GET');
    },

    getMeFromLocal() {
        if (StorageHelper.getItem('userData')) {
            return JSON.parse(StorageHelper.getItem('userData'));
        } else {
            return ApiService.request('/me', 'GET');
        }
    }

}