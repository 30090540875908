import React from 'react'

export default props => {

    const Component = props.component;
    return (
        <div>
            <Component></Component>
       </div>
    )
}