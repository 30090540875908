import React, {useEffect, useState} from 'react'
import {Button, Grid} from "@material-ui/core";
import UserService from "../services/UserService";
import Card from "@material-ui/core/Card";
import { makeStyles } from '@material-ui/core/styles';
import {green} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
        position: 'relative',
        minHeight: 200,
    },

    buttons: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default props => {
    const classes = useStyles();

    const [userData, setUserData] = useState({});

    useEffect(() => {
        fetchMeData();
    }, []);

    // function fetchMeData() {
    //     UserService.getMe().then(response => {
    //         setUserData(response.data)
    //     });
    // }

    function fetchMeData() {
        setUserData(UserService.getMeFromLocal())
    }

    return (
        <div>

            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="DAne kierowcy">
                    <TableBody>
                        <TableRow>
                            <TableCell align="right">kierowca</TableCell>
                            <TableCell align="right">[{userData.id}] {userData.first_name} {userData.last_name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right">auto</TableCell>
                            <TableCell align="right">{userData.tractor_reg_number}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right">naczepa</TableCell>
                            <TableCell align="right">{userData.trailer_reg_number}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid item xs={12} className={classes.buttons}>
                <Button variant="outlined" color="primary" href="/logout">
                    Wyloguj z aplikacji
                </Button>
            </Grid>

        </div>
    );
}