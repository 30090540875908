import  ApiService from './ApiService'

export default {

    getCurrent() {
        return ApiService.request('/order', 'GET');
    },

    confirmLoading(data) {
        return ApiService.request('/order/' + data.id, 'POST', data);
    }

}