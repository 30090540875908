import React, {useEffect, useState} from 'react'
import Fab from '@material-ui/core/Fab';
import CachedIcon from '@material-ui/icons/Cached';
import clsx from "clsx";
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import {Button, Divider, Grid} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";
import OrderService from "../services/OrderService";
import Alert from "@material-ui/lab/Alert";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import moment from 'moment';
import {RecentActors} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
        position: 'relative',
        minHeight: 200,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        },
    },
    buttons: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default props => {
    const classes = useStyles();
    const fabRefresh = clsx(classes.fab, classes.fabGreen);
    const [orderData, setOrderData] = useState({});
    const [openLoadingDialog, setOpenLoadingDialog] = useState(false);
    const [openUnloadingDialog, setOpenUnloadingDialog] = useState(false);

    const [loadDateNew, setLoadDateNew] = useState('');
    const [realLoadDate, setRealLoadDate] = useState('');
    const [realUnloadDate, setRealUnloadDate] = useState('');
    const [realLoadCargoQuantity, setRealLoadCargoQuantity] = useState('');
    const [realUnloadCargoQuantity, setRealUnloadCargoQuantity] = useState('');

    const [openDialog, setOpenDialog] = useState(false);
    const handleDialogOpen = () => {
        setOpenDialog(true)
    }
    const handleDialogClose = () => {
        setOpenDialog(false)
    }

    function fetchCurrentOrder() {
        OrderService.getCurrent().then(response => {
            setOrderData(response.data);
            let newOrderData = JSON.stringify(response.data);
            let oldOrderData = localStorage.getItem('orderData');
            if (newOrderData !== oldOrderData) {
                // alert('Pojawiło się nowe lub zmienione zlecenie');
            }

            localStorage.setItem('orderData', JSON.stringify(response.data));
        });
    }

    const testConsoleLog = () => {
        //console.log(new Date())
        // let loadDate = new Date().getFullYear().toString();
        let loadDate = new Date().toString();
        // setLoadDateNew(loadDate.toString());
        setLoadDateNew(loadDate);
        //console.log(loadDate.toString())
    }

    function ShowLoadDateNew(props) {
        return (
            <div>
                <Button onClick={testConsoleLog}>testConsoleLog</Button>
                <Button onClick={fetchCurrentOrder}>fetchOrder</Button>
                <Typography component={'span'} variant={'body2'}>
                    { loadDateNew }
                </Typography>
                <Typography component={'span'} variant={'body2'}>
                    { props.children }
                </Typography>
            </div>
        );
    }

    useEffect(() => {
        fetchCurrentOrder();
    }, []);

    const handleLoadingDialogOpen = () => {
        setOpenLoadingDialog(true);
    }
    const handleLoadingDialogClose = () => {
        setOpenLoadingDialog(false);
    }

    const handleUnloadingDialogOpen = () => {
        setOpenUnloadingDialog(true);
    }
    const handleUnloadingDialogClose = () => {
        setOpenUnloadingDialog(false);
    }

    const getCoordinates = (setCoordinatesCallback, setErrorCallback) => {
        let geo = navigator.geolocation;
        geo.getCurrentPosition(
            (data) => {
                setCoordinatesCallback(data.coords.latitude, data.coords.longitude);
            },
            (error) => {
                alert(`Błąd lokalizacj: ${error.message} - Pozwól na pobranie lokalizacji przez Twoją przeglądarkę`);
                setErrorCallback(error.message);
            }
        );
    }

    function ShowOrderInfo() {
        if (orderData.id) {
            return <ShowOrderDetails />;
        } else {
            return <ShowNoOrderInformation />;
        }
    }

    function RefreshButton() {
        return (
            <Fab aria-label="Expand" className={fabRefresh} color="inherit" onClick={fetchCurrentOrder}>
                <CachedIcon />
            </Fab>
        )
    }

    function ConfirmButton() {
        if (!orderData.id) {
            return (<></>);
        }

        if (!orderData.real_load_date) {
            return <ConfirmLoadingButton />;
        } else {
            return <ConfirmUnloadingButton />;
        }
    }

    function OrderDescription() {
        if (!orderData.order_description) {
            return (<></>);
        }
        return (
            <>
                <div><br/></div>
                <Divider></Divider>
                Informacje do zlecenia:<br/>
                <Alert severity="info">
                    {orderData.order_description}
                </Alert>
            </>
        )
    }

    function ConfirmLoadingButton() {
        return (
            <>
                <Grid item xs={12} className={classes.buttons}>
                    <Button variant="outlined" color="primary" onClick={handleLoadingDialogOpen}> Potwierdź załadunek</Button>
                </Grid>

                <Grid item xs={12} className={classes.buttons}>
                    <Button variant="outlined" disabled> Potwierdź rozładunek</Button>
                </Grid>
            </>
        );
    }
    function ConfirmUnloadingButton() {
        return (
            <>
                <Grid item xs={12} className={classes.buttons}>
                    <Button variant="outlined" disabled> Potwierdź załadunek</Button>
                </Grid>

                <Grid item xs={12} className={classes.buttons}>
                    <Button variant="outlined" color="primary" onClick={handleUnloadingDialogOpen}> Potwierdź rozładunek</Button>
                </Grid>
            </>
        );
    }

    function ShowOrderDetails() {
        return (
            <div>
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Szczegóły zlecenia<br/>
                        </Typography>
                        <small>[{orderData.id}] {orderData.order_number}</small><br/>

                        Miejsce załadunku:<br/>
                        <b>{orderData.load_place}</b><br/>
                        <Divider></Divider>
                        Data załadunku:<br/>
                        <b>{orderData.load_date}</b><br/>
                        <Divider></Divider>
                        Towar/ilość:<br/>
                        <b>{orderData.cargo_description}</b> / <b>{orderData.cargo_quantity}</b><br/>
                        <Divider></Divider>
                        <Divider></Divider>
                        Miejsce rozładunku:<br />
                        <b>{orderData.unload_place}</b>
                        <Divider></Divider>
                        Data rozładunku:<br/>
                        <b>{orderData.unload_date}</b>
                        <OrderDescription></OrderDescription>
                    </CardContent>
                </Card>
                <ConfirmButton />
                <DialogConfirmLoading />
                <DialogConfirmUnloading />
            </div>
        );
    }

    function ShowNoOrderInformation() {
        return (
            <Alert severity="warning">Brak zleceń do wykonania. Spróbuj odświeżyć listę, może pojawi się nowe zlecenie</Alert>
        );
    }

    function DialogConfirmLoading() {

        const [realLoadCargoQuantity, setRealLoadCargoQuantity] = useState('');
        const [realLoadGeocode, setRealLoadGeocode] = useState('');
        const [realLoadGeocodeError, setRealLoadGeocodeError] = useState('');

        const handleCoordinates = (latitude, longitude) => {
            setRealLoadGeocode(`${latitude}, ${longitude}`)
        }

        const handleCoordinatesError = (error) => {
            setRealLoadGeocodeError(error)
        }

        useEffect(() => {
            if (openLoadingDialog) {
                getCoordinates(handleCoordinates, handleCoordinatesError);
            }
        }, []);

        const handleLoadingConfirmSend = () => {
            const today = moment().format("YYYY-MM-DD HH:mm:ss")
            const todayKey = moment().format("YYYYMMDDHHmmss")
            const data = {
                id: orderData.id,
                real_load_date: today,
                real_load_cargo_quantity: realLoadCargoQuantity,
                real_load_geocode: realLoadGeocode,
                real_load_geocode_error: realLoadGeocodeError,
                data_sent_success: 0
            }

            const orderDataSentKey = 'orderDataSent' + todayKey
            localStorage.setItem(orderDataSentKey, JSON.stringify(data));

            OrderService.confirmLoading(data).then(response => {
                localStorage.removeItem(orderDataSentKey);
                fetchCurrentOrder()
                handleLoadingDialogClose();
                alert('Informacje zostały przesłane');
            });
        }

        return (
            <React.Fragment>
                <Dialog open={openLoadingDialog} onClose={handleLoadingDialogClose} aria-labelledby="form-dialog-title">

                    <DialogTitle id="form-dialog-title-loading">
                        Załadunek
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Potwierdź załadunek towaru <br />
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            label="Masa towaru"
                            type="text"
                            fullWidth
                            autoFocus
                            onChange={e => setRealLoadCargoQuantity(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleLoadingDialogClose} color="primary">
                            Anuluj
                        </Button>
                        <Button onClick={handleLoadingConfirmSend} color="primary" variant="contained">
                            Potwierdzam
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    function DialogConfirmUnloading() {

        const [realUnloadCargoQuantity, setRealUnloadCargoQuantity] = useState('');
        const [realUnloadGeocode, setRealUnloadGeocode] = useState('');
        const [realUnloadGeocodeError, setRealUnloadGeocodeError] = useState('');

        const handleCoordinates = (latitude, longitude) => {
            setRealUnloadGeocode(`${latitude}, ${longitude}`)
        }

        const handleCoordinatesError = (error) => {
            setRealUnloadGeocodeError(error)
        }

        useEffect(() => {
            if (openUnloadingDialog) {
                getCoordinates(handleCoordinates, handleCoordinatesError);
            }
        }, []);

        const handleUnloadingConfirmSend = () => {
            getCoordinates(handleCoordinates, handleCoordinatesError);
            const today = moment().format("YYYY-MM-DD HH:mm:ss")
            const todayKey = moment().format("YYYYMMDDHHmmss")
            const data = {
                id: orderData.id,
                real_unload_date: today,
                real_unload_cargo_quantity: realUnloadCargoQuantity,
                real_unload_geocode: realUnloadGeocode,
                real_unload_geocode_error: realUnloadGeocodeError,
                data_sent_success: 0
            }
            const orderDataSentKey = 'orderDataSent' + todayKey
            localStorage.setItem(orderDataSentKey, JSON.stringify(data));

            OrderService.confirmLoading(data).then(response => {
                localStorage.removeItem(orderDataSentKey);
                fetchCurrentOrder()
                handleUnloadingDialogClose();
                alert('Informacje zostały przesłane');
            });
        }

        return (
            <>
                <Dialog open={openUnloadingDialog} onClose={handleUnloadingDialogClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title-loading">Rozładunek</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Potwierdź rozładunek towaru
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            label="Masa towaru"
                            type="text"
                            fullWidth
                            autoFocus
                            onChange={e => setRealUnloadCargoQuantity(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleUnloadingDialogClose} color="primary">
                            Anuluj
                        </Button>
                        <Button onClick={handleUnloadingConfirmSend} color="primary" variant="contained">
                            Potwierdzam
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return (
        <>
            <ShowOrderInfo />
            <RefreshButton />
        </>
    );
}
