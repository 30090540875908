import axios from 'axios'
import StorageHelper from "../helpers/StorageHelper";

export default {

    Login(username, password)
    {
        const apiUrl = `${process.env.REACT_APP_API_URL}`

        const request = {
            method: 'POST',
            url: `${apiUrl}/token`,
            data: {
                username: username,
                password: password
            },
            headers: this.getRequestHeaders()
        }

        return axios(request);
    },

    logout() {
        StorageHelper.clear();
    },

    getRequestHeaders()
    {
        return {
            'Content-Type': 'application/json'
        }
   },

}
